import { IAddress, IPayout, IUser } from '../interfaces';
import { Address } from './address';
import { CurbNTurfCash } from './cash';
import { User } from './user';

export class Payout implements IPayout {
  id?: number;
  accountBalanceId?: number;
  accountBalance?: CurbNTurfCash;
  requestedOn: number;
  requestedBy: IUser;
  requestedPayoutAddress: IAddress;
  requestedPhone: string;
  status: string;
  requestedAmount: number;
  // This should normally be the same, but allowing for some potential
  // adjustments here if needed, such as two different payout requests
  // processed at the same time.
  paidAmount?: number;
  paidBy?: IUser;
  paidOn?: number;
  paidByMethod?: string;
  note?: string;

  constructor(data?: IPayout) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IPayout) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.requestedPayoutAddress = new Address(this.requestedPayoutAddress);

    this.requestedBy = new User(this.requestedBy);

    this.paidBy = new User(this.paidBy);

    this.accountBalance = new CurbNTurfCash(this.accountBalance);
  }
}
